<template>
  <div>
    <b-input v-model="email" type="email" placeholder="email" disabled/>
    <b-input v-model="password" type="password" placeholder="new password"/>
    <b-button @click="reset">Reset</b-button>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer'
  export default {
    name: 'Login',
    components: {
      Footer,
    },
    data() {
      return {
        email: '',
        token: '',
        password: '',
      };
    },
    mounted() {
      this.email = this.$route.query.email;
      this.token = this.$route.query.token;
    },
    methods: {
      reset() {
        this.axios
          .post("auth/resetPassword", {
            email: this.email,
            token: this.token,
            password: this.password,
          })
          .then(() => {
            this.$router.push('/login');
          })
          .catch((/*err*/) => {
          });
      },
    },
  }
</script>

<style scoped>

</style>